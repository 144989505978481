export const COURSE_DAY_PAGES = {
  ALL_WILL_COME: 'all_will_come',
  ALL_WILL_NOT_COME: 'all_will_not_come',
};

export const COURSE_DAY_SIDEBAR_TABS = {
  FUTURE: 'future',
  PAST: 'past'
};

export const TABS_QUERY = 'tab_name';

export const SIDEBAR_PAGE_QUERY = 'page_sidebar';
export const SIDEBAR_PAGE_SIZE = 'size_sidebar';

export const RESERVED_EXAM_RESULT = {
  SEEN: 'seen',
  CAN_BE_LOADED: 'can_be_loaded',
  STATUS: 'status',
  MISTAKE: 'mistake',
  HAZARD_PERCEPTION: 'hazard_perception'
};

export const COURSE_DAY_FILTERS = {
  DATE: 'date',
  CITY: 'city'
};

export const CHECKLIST_CATEGORIES = {
  BEFORE: 'BEFORE',
  DURING: 'DURING',
  AFTER: 'AFTER'
};

export const COURSE_DAY_HEADERS = [
  {text: '', value: 'edit', class: 'app-table__h_small cell--btns', sortable: false},
  {text: '№', value: 'number', class: 'app-table__h_small cell--index', align: 'left', sortable: false},
  {text: 'Upload', value: 'upload', align: 'center', class: 'app-table__h_small cell--upload', sortable: false},
  {text: 'Reserved Info', value: 'reserved', class: 'cell--reserved', sortable: true},
  {text: 'Presentie', value: 'presentie_status', align: 'center', class: 'cell--presentie', sortable: true},
  {text: 'Name', value: 'candidateName', align: 'left', class: 'cell--name', sortable: true},
  {text: 'Number', value: 'reservation_code', align: 'center', class: 'cell--code', sortable: false},
  {text: 'DOB/Phone', value: 'candidateBirthday', class: 'nowrap cell--bday', align: 'center'},
  {text: 'Result', value: 'exam_result', align: 'left', class: 'cell--result', sortable: true},
  {text: 'Status', value: 'result-status', align: 'left', class: 'result-status cell--status', sortable: false},
  {text: 'Send results', value: 'send_results_emails', class: 'cell--send-results', sortable: false},
  {text: 'Afwezig', value: 'send_sms', class: 'cell--afezig', sortable: false},
  {text: 'Will Come?', value: 'will_come', align: 'left', class: 'cell--wiil_come', sortable: false},
  {text: 'Consent', value: 'consent', align: 'left', class: 'cell--presentie', sortable: false},
  {text: 'App ID', value: 'appId', align: 'center', class: 'cell--appid', sortable: false},
  {text: 'Type', value: 'result-type', align: 'left', class: 'result-type cell--type', sortable: false},
  {text: 'Product', value: 'productName', align: 'center', class: 'cell--product', sortable: true},
  {text: 'Language', value: 'language', align: 'center', class: 'cell--product', sortable: true},
  {text: 'CDN', align: 'left', value: 'number', class: 'nowrap cell--number', sortable: true},
  {text: 'CBR', value: 'accountUsername', align: 'left', class: 'cell--cbr'},
  {text: 'Payment', value: 'payment', class: 'cell--payment', sortable: false},
  {text: 'Transport config', value: 'config', align: 'center', class: 'cell--transport-config', sortable: false},
  {text: 'Course type', value: 'course-type', align: 'left', class: 'nowrap cell--course-type', sortable: false},
  {text: '', value: 'bov', align: 'center', class: 'nowrap cell--email', sortable: false},
  {text: 'Zoom status', value: 'zoom_status', align: 'left', class: 'cell--zoom-status', sortable: true},
  {text: 'Note', value: 'note', align: 'center', class: 'cell--note', sortable: false},
  {text: 'Color', value: 'color', align: 'center', class: 'cell--color', sortable: true}
];
